import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";

const NotFoundPage = () => (
  <Layout>
    <SEO
      seo={{
        metaTitle: "404: Not found",
        metaDescription: "It looks like you got lost",
      }}
    />

    <div className="uk-section">
      <div className="uk-container uk-container-small">
        <h1>UMM.</h1>
        <p>Oh no something went wrong... the sadness.
          <br></br>
          Contact us for help on Twitter or email if the problem persists and we'll work it out, we're in this together.</p>
      </div>
    </div>

  </Layout>
);

export default NotFoundPage;
